<template>
  <div class="row pt-1 m-0">
    <div class="col-12">
      <ValidationObserver ref="form">
        <ValidationProvider
          #default="error"
          rules="required|max:10|min:10"
        >
          <b-form-group
            label="เบอร์โทรศัพท์ (สำหรับติดต่อเพิ่มเติม)"
          >
            <b-form-input
              v-model="phone"
              placeholder="กรุณากรอกเบอร์โทรศัพท์ (สำหรับติดต่อเพิ่มเติม)"
              :state="!error.untouched?error.valid:null"
            />
            <small class="text-danger">{{ error.errors.length > 0 ? 'กรุณากรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง':'' }}</small>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          #default="error"
          rules="required"
        >
          <b-form-group
            label="ประเภทคำร้องเรียน"
          >
            <b-form-select
              v-model="requestType"
              :options="options"
              :state="!error.untouched?error.valid:null"
            />
            <small class="text-danger">{{ error.errors.length > 0 ? 'กรุณาเลือกประเภทคำร้อง':'' }}</small>
          </b-form-group>
        </ValidationProvider>
        <ValidationProvider
          #default="error"
          rules="required"
        >
          <b-form-group
            label="หัวเรื่อง"
          >
            <b-form-input
              v-model="title"
              placeholder="กรุณากรอกหัวเรื่อง"
              :state="!error.untouched?error.valid:null"
            />
            <small class="text-danger">{{ error.errors.length > 0 ? 'กรุณากรุณากรอกหัวเรื่อง':'' }}</small>
          </b-form-group>
        </ValidationProvider>
        <b-form-group
          label="รายละเอียด"
        >
          <b-form-textarea
            v-model="detail"
            placeholder="กรุณากรอกรายละเอียด"
          />
        </b-form-group>
        <div>
          <b-overlay :show="loadLocation">
            <b-list-group-item
              class="d-flex align-items-center justify-content-between"
              variant="danger"
              style="border-radius: 8px"
              @click="showFullMap = true"
            >
              <feather-icon
                class="text-danger"
                icon="MapPinIcon"
                size="2x"
              />
              <div class="w-100 ml-1">
                {{ name }}
              </div>
              <feather-icon
                class="text-danger"
                size="2x"
                icon="ChevronRightIcon"
              />
            </b-list-group-item>
          </b-overlay>
        </div>
        <b-form-group
          class="mt-1"
          label="อัปโหลดรูปภาพ"
        >
          <b-form-file v-model="img" />
          <b-img
            v-if="preview"
            :src="preview"
            class="w-100 mt-1"
            style="border-radius: 8px"
          />
        </b-form-group>
        <b-button
          class="w-100 mt-2 mb-2"
          variant="dark-green"
          :disabled="loadingSubmit"
          @click="submit"
        >
          <b-spinner v-if="loadingSubmit" />ส่งคำร้อง
        </b-button>
      </ValidationObserver>
    </div>
    <template v-if="showFullMap">
      <div
        class="full-map"
      >
        <div
          class="card p-2 mb-0 d-flex flex-row align-items-center nav-full-map"
        >
          <feather-icon
            class="mr-1 cursor-pointer"
            icon="ArrowLeftIcon"
            size="1.5x"
            @click="showFullMap = false;searchText = ''"
          />
          <input
            id="search"
            v-model="searchText"
            class="form-control"
            placeholder="ค้นหาพื้นที่"
            @change="searchLocation"
          >
        </div>
        <div class="w-100 h-100">
          <div
            ref="map"
            class="w-100 h-100"
          />
          <div
            v-if="searchLocationList.length > 0"
            class="searchLocation"
          >
            <div
              v-for="item in searchLocationList"
              :key="item._id"
              class="w-100"
            >
              <div class="d-flex pl-1 pr-1 pt-1">
                <b-img
                  style="border-radius: 8px"
                  :src="mock"
                  width="100px"
                  height="100px"
                />
                <div
                  style="margin-left: 8px"
                >
                  <h4 class="text-bold">
                    หลุมมมมมมเว้ยยยยย
                  </h4>
                  <div>
                    โดย: Pakkaphon
                  </div>
                  <div class="text-detail">
                    หลุมมมมมมเว้ยยยยย หลุมจ้า มาแบฟหงวฟหวดทงฟหเวสงฟหเสวมฟหยดทแฟสวดทฟหวดสทฟวหทสดเมเฟวทหวเสฟทหเวสฟทหเฟงสหเฟงหเสฟงหเวสงฟ
                    วฟสกเทฟวหเงฟหฟหงเสฟหเงหฟวเสงฟสหวเงฟหวมเงฟหม
                  </div>
                </div>
              </div>
              <hr>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="lineAuth">
      <div
        class="full-map"
        style="z-index: 99"
      >
        <div
          class="card m-0 w-100 h-100 d-flex align-items-center justify-content-center"
          style="border-radius: 0px"
        >
          <b-button
            variant="success"
            @click="loginLine"
          >
            ลงชื่อเข้าใช้งานผ่าน LINE
          </b-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormSelect,
  BFormInput,
  BFormTextarea,
  BListGroupItem,
  BOverlay,
  BImg,
  BFormFile,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { Loader } from '@googlemaps/js-api-loader'
import mock from '@/assets/images/mock/empty.jpeg'
import liff from '@line/liff'
import axios from 'axios'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min, max } from '@validations'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    BListGroupItem,
    BOverlay,
    BImg,
    BFormFile,
    BButton,
    BSpinner,
  },
  data() {
    return {
      min,
      max,
      phone: '',
      lineAuth: false,
      lineProfile: undefined,
      title: '',
      detail: '',
      userId: undefined,
      img: undefined,
      preview: undefined,
      google: undefined,
      map: undefined,
      marker: undefined,
      service: undefined,
      loadLocation: false,
      loadingSubmit: false,
      showFullMap: false,
      searchText: '',
      searchLocationList: [],
      name: 'ไม่ทราบ',
      location: {
        lat: 13.348721686329366,
        lng: 101.0833993312976,
      },
      requestType: null,
      mock,
      required,
    }
  },
  computed: {
    options() {
      return this.$store.state.app.requestOptions
    },
  },
  watch: {
    showFullMap: {
      handler(v) {
        this.$nextTick(() => {
          if (v) {
            this.renderMap()
          }
        })
      },
    },
    location: {
      handler(v) {
        if (v) {
          this.loadNameLocation()
        }
      },
    },
    searchText: {
      handler(v) {
        if (v !== '') {
          // this.searchLocation(v) // for feature
        } else {
          this.searchLocationList = []
        }
      },
    },
    img: {
      handler(v) {
        this.preview = URL.createObjectURL(v)
      },
    },
  },
  async created() {
    await liff.init({
      liffId: '1657233329-92n7voR4', // Use own liffId
    })
    if (liff.isLoggedIn()) {
      this.lineAuth = false
      this.lineProfile = await liff.getProfile()
      // register user here
      const res = await axios.post('/user/update/line', this.lineProfile)
      this.userId = res.data.userId
    } else {
      this.lineAuth = true
    }
    await this.initMap()
    this.loadLocation = true
    this.location = await this.getCurrentLocation()
    this.loadLocation = false
  },
  methods: {
    async submit() {
      const result = await this.$refs.form.validate()
      if (this.userId && result) {
        try {
          this.loadingSubmit = true
          const formData = new FormData()
          formData.append('datas', JSON.stringify({
            userId: this.userId,
            phone: this.phone,
            title: this.title,
            detail: this.detail,
            location: this.location,
            requestType: this.requestType,
          }))
          formData.append('image', this.img)
          const res = await axios.post('/request', formData)
          this.$router.push({
            name: 'home',
            query: {
              // eslint-disable-next-line no-underscore-dangle
              request: res.data.result._id,
            },
          })
        } catch (error) {
          console.log(error)
        } finally {
          this.loadingSubmit = false
        }
      }
    },
    async initMap() {
      const loader = new Loader({
        apiKey: 'AIzaSyA15zZ_DKfS-SJLZ-b4hFFcx-1q4QXFC5E',
        version: 'weekly',
        libraries: ['places'],
        region: 'TH',
        language: 'th',
      })
      this.google = await loader.load()
    },
    loginLine() {
      liff.login()
    },
    renderMap() {
      const mapOptions = {
        center: {
          lat: this.location.lat,
          lng: this.location.lng,
        },
        zoom: 14,
      }
      this.map = new this.google.maps.Map(this.$refs.map, mapOptions)
      this.marker = new this.google.maps.Marker({
        position: {
          lat: this.location.lat,
          lng: this.location.lng,
        },
        map: this.map,
        draggable: true,
      })
      this.marker.addListener('dragend', () => {
        this.location = {
          lat: this.marker.position.lat(),
          lng: this.marker.position.lng(),
        }
        this.map.setCenter(this.location)
      })
    },
    loadNameLocation() {
      const latlng = new this.google.maps.LatLng(this.location.lat, this.location.lng)
      const geocoder = new this.google.maps.Geocoder()
      geocoder.geocode({ location: latlng }, res => {
        console.log(res)
        if (res[0]) {
          this.name = res[0].formatted_address
        } else {
          this.name = 'ไม่ทราบ'
        }
      })
    },
    searchLocation() {
      const request = {
        query: this.searchText,
        fields: ['name', 'geometry'],
      }
      const service = new this.google.maps.places.PlacesService(this.map)
      service.findPlaceFromQuery(request, (results, status) => {
        if (status === this.google.maps.places.PlacesServiceStatus.OK) {
          // this.searchLocationList = results // for feature
          if (results[0]) {
            this.map.setCenter(results[0].geometry.location)
            this.marker.setPosition(results[0].geometry.location)
            this.location = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            }
          }
        }
      })
    },
    async getCurrentLocation() {
      try {
        if (navigator.geolocation) {
          const location = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(loc => {
              resolve({
                lat: loc.coords.latitude,
                lng: loc.coords.longitude,
              })
            }, err => {
              reject(err)
            })
          })
          return location
        }
        return {
          lat: 13.348721686329366,
          lng: 101.0833993312976,
        }
      } catch (error) {
        console.log(error)
        return {
          lat: 13.348721686329366,
          lng: 101.0833993312976,
        }
      }
    },
  },
}
</script>

<style lang="scss">
  .full-map {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    z-index: 5;
  }

  .searchLocation {
    position: relative;
    top: -100%;
    height: 100%;
    background: #ffffff;
  }

  .nav-full-map {
    border-radius: 0px;
    z-index: 2;
  }

  #search {
    border: none;
    height: 40px;
    width: 100%;
  }

  #search:focus{
    outline: none;
    box-shadow: none;
  }

  .text-detail {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
