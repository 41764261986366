<template>
  <div
    class="d-flex flex-column align-items-stretch"
    style="
    height: 100vh;
    overflow: scroll;
"
  >
    <div
      class="d-flex align-items-center position-sticky"
      style="background-color: #148827;"
    >
      <b-img
        :src="abtLogo1"
        width="60px"
        style="margin: 8px"
      />
      <h4
        class="text-white mb-0"
        style="margin-left: 8px"
      >
        องค์การบริหารส่วนตำบลมาบไผ่
      </h4>
    </div>
    <div class="bg-white h-100 w-100">
      <form-request />
    </div>
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'
import abtLogo1 from '@/assets/images/logo/abt-logo1.png'
import FormRequest from '@/views/components/FormRequest.vue'

export default {
  components: {
    BImg,
    FormRequest,
  },
  data() {
    return {
      abtLogo1,
    }
  },
}
</script>

<style>

</style>
